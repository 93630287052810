import React, { useState, useEffect } from "react";
import "../styles.css";
import { Form, Button } from "react-bootstrap";
import swal from "sweetalert";
import accountImg from "../../../../images/account.jpg";
const CertFAQ = ({
  data,
  openSection,
  handleToggle,
  t,
  m,
  token,
  id,
  dataFilter,
  handleUpdate,
  employee,
}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [windowContent, setWindowContent] = useState("");
  const urlLink = process.env.REACT_APP_API_URL;

  const handleAddClick = () => {
    const addContent = (
      <div className="edit-form">
        <h3>{t.addnewrlcertification}</h3>
        <Form onSubmit={handleAddSubmit}>
          <Form.Group controlId="formIssueDate">
            <Form.Label>{t.issuedate}:</Form.Label>
            <Form.Control
              type="date"
              name="IssueDate"
              className="edit__input"
            />
          </Form.Group>

          <Form.Group controlId="formValidityDate">
            <Form.Label>{t.validityDate}:</Form.Label>
            <Form.Control
              type="date"
              name="ValidityDate"
              className="edit__input"
            />
          </Form.Group>

          <Form.Group controlId="formDocumenNo">
            <Form.Label>{t.documentNumber}:</Form.Label>
            <Form.Control
              type="text"
              name="DocumentNo"
              className="edit__input"
            />
          </Form.Group>

          <Form.Group controlId="formPlaceOfIssue">
            <Form.Label>{t.placeofissue}:</Form.Label>
            <Form.Control
              type="text"
              name="PlaceOfIssue"
              className="edit__input"
            />
          </Form.Group>

          <Form.Group controlId="formIssuedBy">
            <Form.Label>{t.issuedBy}:</Form.Label>
            <Form.Control type="text" name="IssuedBy" className="edit__input" />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label>{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input"
            />
          </Form.Group>

          <div className="edit__btn-wrapper">
            <Button className="edit__btn" type="submit">
              {t.save}
            </Button>
          </div>
        </Form>
      </div>
    );
    setWindowContent(addContent);
    setIsWindowOpen(true);
  };

  const handleAddSubmit = async (e) => {
    e.preventDefault();
    const formElements = e.target.elements;
    const fileInput = formElements.File;

    // Prepare FormData
    const formData = new FormData();
    formData.append(
      "IssueDate",
      formatDateForInput(formElements.IssueDate.value)
    );
    formData.append(
      "ValidityDate",
      formatDateForInput(formElements.ValidityDate.value)
    );
    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }

    formData.append("Doc_Type_En", parseInt(13, 10));
    formData.append("Employee", parseInt(id, 10));
    formData.append("DocumenNo", formElements.DocumentNo.value);
    formData.append("PlaceOfIssue", formElements.PlaceOfIssue.value);
    formData.append("IssuedBy", formElements.IssuedBy.value);

    try {
      const response = await fetch(`${urlLink}/employee/RusLCert/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Do not set 'Content-Type' for FormData
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        swal(t.success.charAt(0).toUpperCase() + t.success.slice(1), t.RLcertificationaddedsuccessfully, "success");
        handleCloseWindow();
        handleUpdate();
      } else {
        let errorData;
        try {
          const errorText = await response.text();
          errorData = JSON.parse(errorText);
        } catch (parseError) {
          errorData = { message: "Unable to parse error response" };
        }

        console.error("Error response from server:", errorData);
        throw new Error(errorData.message || t.failedtoaddRLcertification);
      }
    } catch (error) {
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const handleEditClick = () => {
    const editContent = (
      <div className="edit-form">
        <h3>{t.editrlcertification}</h3>
        <Form onSubmit={handleEditSubmit}>
          <Form.Group controlId="formIssueDate">
            <Form.Label>{t.issuedate}:</Form.Label>
            <Form.Control
              type="date"
              name="IssueDate"
              className="edit__input"
              defaultValue={formatDateToShow(data?.RusLCert[0]?.IssueDate)}
            />
          </Form.Group>

          <Form.Group controlId="formValidityDate">
            <Form.Label>{t.validityDate}:</Form.Label>
            <Form.Control
              type="date"
              name="ValidityDate"
              className="edit__input"
              defaultValue={formatDateToShow(data?.RusLCert[0]?.ValidityDate)}
            />
          </Form.Group>

          <Form.Group controlId="formDocumentNo">
            <Form.Label>{t.documentNumber}:</Form.Label>
            <Form.Control
              type="text"
              name="DocumentNo"
              className="edit__input"
              defaultValue={data?.RusLCert[0]?.DocumenNo}
            />
          </Form.Group>

          <Form.Group controlId="formPlaceOfIssue">
            <Form.Label>{t.placeofissue}:</Form.Label>
            <Form.Control
              type="text"
              name="PlaceOfIssue"
              className="edit__input"
              defaultValue={data?.RusLCert[0]?.PlaceOfIssue}
            />
          </Form.Group>

          <Form.Group controlId="formIssuedBy">
            <Form.Label>{t.issuedBy}:</Form.Label>
            <Form.Control
              type="text"
              name="IssuedBy"
              className="edit__input"
              defaultValue={data?.RusLCert[0]?.IssuedBy}
            />
          </Form.Group>

          <Form.Group controlId="formFileUpload">
            <Form.Label>{t.upload} (PDF/Image):</Form.Label>
            <Form.Control
              type="file"
              name="File"
              accept=".pdf, image/*"
              className="edit__input"
            />
          </Form.Group>

          <div className="edit__btn-wrapper">
            <Button className="edit__btn" type="submit">
              {t.save}
            </Button>
          </div>
        </Form>
      </div>
    );

    setWindowContent(editContent);
    setIsWindowOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formElements = e.target.elements;
    const fileInput = formElements.File;

    // Prepare FormData
    const formData = new FormData();
    formData.append(
      "IssueDate",
      formatDateForInput(formElements.IssueDate.value)
    );
    formData.append(
      "ValidityDate",
      formatDateForInput(formElements.ValidityDate.value)
    );
    formData.append("Doc_Type_En", parseInt(13, 10)); // Assuming a static Doc_Type_En
    formData.append("Employee", parseInt(id, 10));
    formData.append("DocumenNo", formElements.DocumentNo.value);
    formData.append("PlaceOfIssue", formElements.PlaceOfIssue.value);
    formData.append("IssuedBy", formElements.IssuedBy.value);
    if (fileInput.files[0]) {
      formData.append("file", fileInput.files[0]);
    }

    try {
      const response = await fetch(
        `${urlLink}/employee/RusLCert/${data.RusLCert[0].id}/`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`, // Do not set 'Content-Type' for FormData
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        swal(t.success.charAt(0).toUpperCase() + t.success.slice(1), t.RLcertificationupdatedsuccessfully, "success");
        handleCloseWindow();
        handleUpdate();
      } else {
        // Check if the response is JSON and handle it accordingly
        let errorData;
        try {
          const errorText = await response.text();
          errorData = JSON.parse(errorText);
        } catch (parseError) {
          errorData = { message: "Unable to parse error response" };
        }

        console.error("Error response from server:", errorData);
        throw new Error(
          errorData.message || t.failedtoupdateRLcertification
        );
      }
    } catch (error) {
      swal(t.error.charAt(0).toUpperCase() + t.error.slice(1), error.message, "error");
    }
  };

  const formatDateForInput = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  const formatDateToShow = (dateString) => {
    if (!dateString) return "";
    const [day, month, year] = dateString.split(".");
    return `${year}-${month}-${day}`;
  };

  const handleCloseWindow = () => {
    setIsWindowOpen(false);
  };

  const handlePdfClick = () => {
    const file = data.RusLCert[0]?.file;

    const cleanFileUrl = file?.split("?")[0];

    let content;
    if (cleanFileUrl?.endsWith(".pdf")) {
      content = (
        <iframe
          src={file}
          width="100%"
          height="100%"
          title="PDF Viewer"
          frameBorder="0"
          allowFullScreen
        />
      );
    } else {
      content = (
        <img
          className="sliding-image"
          src={file || accountImg}
          alt="PDF or Image"
        />
      );
    }

    setWindowContent(content);
    setIsWindowOpen(true);
  };
  const [datacheck, setDatacheck] = useState(true)
  const [effectState, setEffectState] = useState(false)

  useEffect(() => {
    if(!data?.RusLCert[0]){
      setDatacheck(false)
    }
    for(let i in data?.RusLCert[0]){
      if(typeof data?.RusLCert[0]?.[i] === "string" && data?.RusLCert[0]?.[i] == ""){
        setDatacheck(false)
      }
      else if(data?.RusLCert[0]?.[i] === null&& i != "file") setDatacheck(false)
      else if(data?.RusLCert[0]?.[i] instanceof  Object && Object.keys(data?.RusLCert[0]?.[i]).length == 0){
        setDatacheck(false)
      }
      else setDatacheck(true)
    }
    setEffectState(!effectState)
  }, [effectState])
  return (
    <div style={!datacheck ? {background: "rgb(223,223,223)"} : null} className="faq-container7">
      <div
        onClick={() => handleToggle("certifications")}
        className="faq__t-wrapper"
      >
        <h5 className="toggle-title">{t.certificationsInformation}</h5>
        <p className="faq__header-seria">
          {data?.RusLCert && data?.RusLCert.length > 0
            ? data?.RusLCert[0].IssueDate
            : t.noCertificationsInformation}
        </p>
      </div>
      <div
        className={`collapsible-content ${
          openSection === "certifications" ? "open" : ""
        }`}
      >
        {data?.RusLCert && data?.RusLCert.length > 0 ? (
          <div className="faq-details">
            <div className="faq__top-wrapper">
              <p className="faq__section-title">{t.certifications}</p>
              <span className="faq__line"></span>

              <div className="faq__wrapper">
                <p className="faq__topsubheader">{t.validityDate}</p>
                <p className="faq__country-code">
                  {data?.RusLCert[0].ValidityDate}
                </p>
              </div>
            </div>
            <div className="faq__main-wrapper">
              <div className="faq__info">
                <div className="faq-field">
                  <span className="field-label">{m.fullname}:</span>
                  <p className="field__data">
                    {employee?.firstname} {employee?.surname}
                  </p>
                </div>
                <div className="faq__birthwrapper">
                  <div className="faq-field">
                    <span className="field-label">{t.issueby}:</span>
                    <p className="field__data">{data?.RusLCert[0].IssuedBy}</p>
                  </div>
                  <div className="faq-field">
                    <span className="field-label">{t.placeofissue}:</span>
                    <p className="field__data">
                      {data?.RusLCert[0].PlaceOfIssue}
                    </p>
                  </div>
                </div>
                <div className="faq__birthwrapper">
                  <div className="faq-field">
                    <span className="field-label">{t.issuedate}:</span>
                    <p className="field__data">{data?.RusLCert[0].IssueDate}</p>
                  </div>
                </div>

                <button
                  className="btn btn-secondary mt-2 me-2 faq__edit-btn"
                  onClick={handleEditClick}
                >
                  <i className="fa fa-pencil"></i> {t.edit}
                </button>

                <button
                  className=" btn btn-secondary mt-2 faq__pdf-btn"
                  onClick={handlePdfClick}
                >
                  PDF
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data">
            {dataFilter === "latest" ? (
              <div className="no-data-wrapper">
                <p className="no-data">{t.noData}</p>
                <div className="faq__add-btn-wrapper">
                  <Button className="faq__edit-btn" onClick={handleAddClick}>
                    {t.addrlcertificate}
                  </Button>
                </div>
              </div>
            ) : (
              <p>{t.noData}</p>
            )}
          </div>
        )}
      </div>

      {isWindowOpen && (
        <div className="overlay show" onClick={handleCloseWindow}>
          <div
            className="sliding-window show"
            onClick={(e) => e.stopPropagation()}
          >
            <span className="close-btn" onClick={handleCloseWindow}>
              &times;
            </span>
            {windowContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default CertFAQ;