import React, {useEffect, useState} from 'react'
import { Form } from "react-bootstrap";
import './CountrySearch.css'
const CountrySearch = ({language, countries, data, name, t}) => {
  const [searchCountryInputPut, setSearchCountryInputPut] = useState(data?.Passport[0]?.citizenship_data?.["Country"+language.toUpperCase()] || "")
  const [countriesData, setCountriesData] = useState(countries || [])
  const [countrySearchBoxState, setCountrySearchBoxState] = useState(false)
  const [countryStyleIndex, setCountryStyleIndex] = useState(data?.Passport[0]?.citizenship_data?.id || null)
  useEffect(() => {
    setCountriesData(countries.filter(e=>e["Country"+language.toUpperCase()].slice(0, searchCountryInputPut.length).toLowerCase() == searchCountryInputPut.trim().toLowerCase()))
  }, [searchCountryInputPut])
  return (
    <div>
      <Form.Control
              type="text"
              name={name}
              value={searchCountryInputPut}
              className="edit__input"
              onBlur={() => {
                setTimeout(() => {
                  setCountrySearchBoxState(false)
                }, 300)
              }}
              onInput={e => {
                setSearchCountryInputPut(e.target.value)
              }}
              onFocus={() => {
                setCountrySearchBoxState(true)
              }}
              placeholder={t.selectnationality}
            />
            {countrySearchBoxState && <div className="countries_search__wrapper">
              <div onMouseLeave={() => {
                setCountryStyleIndex(data?.Passport[0]?.citizenship_data?.id || null)
              }} className="countries_container">
              {countriesData.map((e) => 
             <div onMouseOver={() => {
               setCountryStyleIndex(e.id)
             }} onClick={() => {
              setSearchCountryInputPut(e["Country"+language.toUpperCase()])
              setCountrySearchBoxState(false)
              }} style={countryStyleIndex==e.id ? {background:"purple", color: "#fff"} : null} className="country_search_card" key={e.id}>{e["Country"+language.toUpperCase()]}</div>
              )}
            </div>
            </div>}
    </div>
  )
}

export default CountrySearch
